@font-face {
  font-display: swap;
  font-family: marbachlig;
  font-style: normal;
  font-weight: normal;
  src: url("marbachlig-webfont.e0d90e74.woff2") format("woff2"), url("marbachlig-webfont.4d4081fc.woff") format("woff");
}

:root {
  --green-light: #eaf5f4;
  --green-med-light: #82c7bf;
  --green-med: #2fa295;
  --green-dark-med: #147066;
  --green-dark: #030e0d;
  --purple-light: #edeae5;
  --purple-med-light: #8d8a9e;
  --purple-med: #2e294e;
  --purple-dark-med: #151324;
  --purple-dark: #050408;
  --brand-color: var(--purple-light);
  --link-color: var(--green-med);
}

em {
  font-size: .8em;
}

*, :before, :after {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: marbachlig, -apple-system, SF UI Text, Avenir Next, Helvetica Neue, Helvetica, Arial, sans-serif;
}

body {
  background-color: var(--brand-color);
  font-size: 18px;
}

h1 {
  color: var(--link-color);
  text-align: center;
  margin: 40px 0;
  padding: 0 20px;
  font-size: 60px;
}

body h1, h2, h3, h4, h5, h6 {
  font-family: marbachlig, Helvetica, sans-serif;
}

p {
  margin: 0 0 10px;
  line-height: 29px;
}

a {
  color: var(--link-color);
}

nav li a {
  color: var(--green-dark-med);
  opacity: .6;
  text-decoration: none;
  transition: all .3s;
}

img {
  width: 100%;
}

hr {
  background: #0000001a;
  border: 0;
  width: 100%;
  height: 1px;
  margin: 20px 0;
}

.marginnote {
  font-size: 80%;
  font-style: italic;
}

small {
  width: 100%;
  font-size: 90%;
  display: block;
}

ul, ol {
  margin: 0 0 20px;
  line-height: 29px;
}

figure {
  margin: 0 0 10px;
}

pre {
  background: #0000000d;
  padding: 10px;
  overflow: auto;
}

.site-title h2 {
  margin: 0;
}

.site-title a {
  color: var(--green-dark-med);
  text-decoration: none;
}

.tagline {
  color: var(--green-dark-med);
  font-size: .8em;
}

header {
  align-items: center;
  width: 950px;
}

.home {
  color: var(--green-med);
}

.home:hover {
  color: auto;
}

.main-link:hover {
  font-weight: bold;
}

.flex-grid {
  flex-shrink: 0;
  justify-content: center;
  display: flex;
}

.flex-grid--wrap {
  flex-wrap: wrap;
}

.flex-grid--sb {
  justify-content: space-between;
}

.flex-grid--col {
  flex-direction: column;
}

.flex-grid__item {
  background: #fff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  width: calc(33% - 40px);
  margin: 20px;
  padding: 40px;
  text-decoration: none;
  transition: all .3s;
  display: flex;
  box-shadow: 0 30px 50px #0000004d;
}

.flex-grid__item:hover {
  background: var(--link-color);
  transform: scale(1.05);
  box-shadow: 0 40px 60px #0003;
}

.flex-grid__item:hover h2.home-title, .flex-grid__item:hover p.home-summary {
  color: #fff;
}

.flex-grid__item h2 {
  margin: 0 0 10px;
}

.flex-grid__item p {
  color: #2d2d2d;
  margin: 0;
}

.flex-grid__item--full-width {
  max-width: 650px;
  width: calc(100% - 40px) !important;
}

.flex-grid__item.flex-grid__item--small-item {
  width: calc(20% - 20px);
  max-width: calc(40% - 20px);
  margin: 20px;
  padding: 10px 5px;
}

.flex-grid__item--no-hover:hover {
  background: #fff;
  transform: none;
  box-shadow: 0 30px 50px #0000004d;
}

.flex-grid__item--no-hover p {
  margin: 0 0 20px;
}

.w-100 {
  width: 100%;
}

.m-0 {
  margin: 0 !important;
}

.m-40 {
  margin: 40px auto;
}

.m-60 {
  margin: 60px auto;
}

.p-60 {
  padding: 0 60px;
}

.t-center {
  text-align: center;
}

.t-right {
  text-align: right;
}

.c-dark {
  color: #2d2d2d;
}

nav ul {
  padding: 0;
  list-style: none;
}

nav ul :first-child {
  margin-left: 0;
}

nav ul :last-child {
  margin-right: 0;
}

nav li {
  margin: 0 .5em;
  font-size: 20px;
  display: inline-block;
}

nav li a:hover {
  opacity: 1;
  text-decoration: underline;
}

nav li a[data-current="current item"] {
  opacity: 1;
  font-weight: bold;
  text-decoration: underline;
}

a[rel="tag"], a[rel="tag"]:visited {
  background: var(--link-color);
  color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
  text-decoration: none;
}

form {
  padding: 2em 0;
  display: grid;
}

form label {
  display: none;
}

input, textarea, button {
  width: 100%;
  margin-bottom: 1em;
  padding: 1em;
  font-family: marbachlig, Avenir Next, sans-serif;
  font-size: 1rem;
}

input, textarea {
  border: 1px solid #000;
  border-radius: 5px;
}

button {
  background-color: var(--link-color);
  border: 1px solid var(--link-color);
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}

@media screen and (width <= 1100px) {
  .flex-grid__item {
    width: calc(50% - 40px);
  }
}

@media screen and (width <= 768px) {
  .site-title h2 {
    text-align: center;
  }

  body {
    font-size: 16px;
  }

  .flex-grid__item {
    margin: 10px;
    padding: 20px;
    width: 100% !important;
  }

  .flex-grid__item--full-width {
    margin: 10px 0;
  }

  h1 {
    margin: 20px 0;
    font-size: 30px;
  }

  p, ol, ul {
    line-height: 25px;
  }

  .p-60 {
    padding: 0 20px;
  }

  nav ul li {
    font-size: 16px;
  }

  .flex-break {
    text-align: center;
    width: 100%;
    display: block;
  }
}

code[class*="language-"], pre[class*="language-"] {
  color: #ccc;
  -webkit-hyphens: none;
  hyphens: none;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  text-align: left;
  white-space: pre;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  background: none;
  font-family: marbachlig, Monaco, Andale Mono, Ubuntu Mono, monospace;
  line-height: 1.5;
}

pre[class*="language-"] {
  margin: 0 0 20px;
  padding: 1em;
  overflow: auto;
}

:not(pre) > code[class*="language-"], pre[class*="language-"] {
  background: #2d2d2d;
}

:not(pre) > code[class*="language-"] {
  white-space: normal;
  border-radius: .3em;
  padding: .1em;
}

.token.comment, .token.block-comment, .token.prolog, .token.doctype, .token.cdata {
  color: #999;
}

.token.punctuation {
  color: #ccc;
}

.token.tag, .token.attr-name, .token.namespace, .token.deleted {
  color: #e2777a;
}

.token.function-name {
  color: #6196cc;
}

.token.boolean, .token.number, .token.function {
  color: #f08d49;
}

.token.property, .token.class-name, .token.constant, .token.symbol {
  color: #f8c555;
}

.token.selector, .token.important, .token.atrule, .token.keyword, .token.builtin {
  color: #cc99cd;
}

.token.string, .token.char, .token.attr-value, .token.regex, .token.variable {
  color: #7ec699;
}

.token.operator, .token.entity, .token.url {
  color: #67cdcc;
}

.token.important, .token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.token.inserted {
  color: green;
}

.rss {
  display: inline-flex;
}

.rss div {
  background-color: var(--link-color);
  box-sizing: content-box;
  color: var(--brand-color);
  border-radius: 25%;
  height: 80%;
  padding: 0 .2em;
  font-size: 12px;
}

.insta-flex {
  flex-flow: row;
  justify-content: space-between;
  display: flex;
}

.insta {
  flex-basis: 20%;
  align-self: flex-end;
  width: auto;
  max-width: 20%;
  height: auto;
}
/*# sourceMappingURL=index.css.map */
